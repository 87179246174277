<script setup lang="ts">
const { open } = useSearch();
</script>

<template>
	<button class="flex items-center gap-2" @click="open">
		Zoeken
		<SvgIcon :name="'search'" />
	</button>
</template>
